import { Article } from '@/Article';
import { CommonHero } from '@/CommonHero';
import { ContactForm } from '@/ContactForm';
import { ContactHours } from '@/ContactHours';
import { flexCenter, px } from '@/layout/styles/classes';
import { Statement } from '@/Statement';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const Flex = styled.section`
    > div {
        :last-of-type {
            ${px};
            background-color: ${({ theme }) => theme.colors.light};
            padding-top: 32px;
            padding-bottom: 32px;
        }
    }

    @media (min-width: 1024px) {
        ${flexCenter};

        > div {
            width: 50%;
        }
    }
`;

export default function ContactUs() {
    return (
        <Fragment>
            <CommonHero>
                <StaticImage
                    src="../../assets/images/contact-hero.jpg"
                    alt="opened notebook in front of a laptop"
                    loading="eager"
                />
            </CommonHero>
            <Statement />
            <Article>
                <h2>Your First Visit</h2>

                <p>Your first visit with us may include:</p>

                <ul></ul>
                <li>Comprehensive oral examination</li>
                <li>Full mouth digital x-rays</li>
                <li>Oral cancer screening</li>
                <li>Oral hygiene instruction</li>
                <li>Teeth cleaning</li>
                <li>
                    One-on-one consultation with our dentist to discuss your needs, concerns, goals,
                    and
                </li>
                <li>treatment options</li>

                <p>
                    During your visit, we will answer any and all questions about your oral health.
                    We will provide a detailed financial consultation to ensure your treatments are
                    timed effectively and affordably.
                </p>
            </Article>
            <Flex>
                <ContactHours />
                <div>
                    <ContactForm />
                </div>
            </Flex>
        </Fragment>
    );
}
